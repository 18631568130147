.recommended-title, .all-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 25px;
}

.recommended-container {
  margin-bottom: 50px;
}

.all-jobs {
  overflow: auto;
}

.see-on-the-map {
  margin-bottom: 40px;
}

.content-mapbox {
  margin: -30px;
  height: 80%;
}