.job-form-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-top: 10px solid #FF7341;
  border-radius: 4px;
  width: 100%;
  padding: 30px;
  margin-bottom: 30px
}

.job-form-title {
  font-weight: bold;
  font-size: 2.7rem;
  margin-bottom: 30px;
  color: #fff;
}

.form-group {
  padding-bottom: 30px;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group-header {
  position: relative;
}


.form-word-count {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.7;
}

.picker-title {
  margin-bottom: 5px;
}

.remove-icon {
  margin-left: -5px;
  color: #aaa;
  /*margin-top: 2px;*/
}

.remove-icon:hover {
  cursor: pointer;
}

.selected-category {
  /*font-weight: bold;*/
  margin-bottom: 10px;
}

.selected-section {
  margin-bottom: 20px;
}

.selected-container {
  margin-right: 15px;
  margin-bottom: 0px;
  display: inline-block;
}

.validation-message {
  color: #FF7341;
  margin-top: 5px;
  /*position: absolute;*/
  /*bottom: 20px;*/
}

/*.validations-container {
  color: #fff;
  position: absolute;
  right: 0;
}

.validation-message {
  display: block;
}

.validation-text {
  color: #FF7341;
}*/
