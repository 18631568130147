.header-title {
  font-weight: bold;
  font-size: 2.7rem;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.header-subtitle {
  color: #fff;
  font-size: 1.4rem;
  margin-top: 0px;
  opacity: 0.7;
}

.paginator {
  margin-top: 30px;
  color: #333;
  border-radius: 4px;
  text-align: center;
}

.page-item {
  border-radius: 4px;
}

.page-link {
  color: #333;
  border: none;
  font-size: 1.1rem;
  padding: 10px 20px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  outline: none !important;
}

.page-link:focus {
  box-shadow: none;;
}

.page-link:hover {
  color: #333;
}

.page-item.active .page-link {
  background: #333;
  border-color: #333;
  color: #fff;
}