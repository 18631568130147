.dash-card {
  background: #fff;
  padding: 15px 25px;
  border-radius: 4px;
  border-top: 7px solid #FF7F50;
  color: #333;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.dash-card-title {
  font-weight: bold;
  font-size: 1.2rem;
  opacity: 0.7;
  margin-bottom: 5px;
}

.dash-card-value {
  font-size: 3.3rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.dash-card-link {
  opacity: 0.7;
  transition: 0.3s ease;
}

.dash-card-link:hover {
  text-decoration: none;
  color: #FF7341;
  transition: 0.3s ease;
}